<template>
  <div>
    <el-form
      ref="form"
      label-width="100px"
      class="view pa24"
      label-position="left"
    >
      <el-form-item label="商品分类" v-if="state==0">
        <p style="color: #51CDCB;">{{ detailsDate.goodsTypeName }}</p>
      </el-form-item>
      <hr
          v-if="state==0"
        style="
          background-color: #e4e4e4;
          border: none;
          height: 1px;
          margin: 30px 0;
        "
      />

      <el-form-item label="商品名称"
        ><p>{{ detailsDate.goodsName }}</p>
      </el-form-item>

      <el-form-item label="商品总库存"> {{ detailsDate.goodsNum }}</el-form-item>

      <el-form-item label="商品价格">
        <span style="color:red">￥{{ (detailsDate.price/100).toFixed(2) }}</span>
        <span style="color:red">~</span>
        <span style="color:red">￥{{ detailsDate.maxPrice }}</span>
      </el-form-item>

      <el-form-item label="商品规格" v-if="state==0">
<!--        <template v-for="(item, index) in detailsDate.goodSpecModelList">-->
<!--          <div :key="index">-->
<!--            <span>{{ item.specName }}:</span>-->
<!--            <template v-for="(a, b) in item.goodSpecModelList">-->
<!--              <span-->
<!--                :key="b"-->
<!--                style="padding:5px 10px;border: 1px solid rgb(81, 205, 203);border-radius:5px;margin-left:5px"-->
<!--                >{{ a.specNum }} <i style="color:red">{{ (a.price/100).toFixed(2) }}元 </i>-->
<!--              </span>-->
<!--            </template>-->
<!--          </div>-->
<!--        </template>-->
        <template v-for="(item, index) in detailsDate.goodSpecModelList">
          <div :key="index">
            <span>{{ item.specAttribute }}<span v-if="item.specAttribute1">-{{item.specAttribute1}}</span> <span v-if="item.specAttribute2">-{{item.specAttribute2}}</span><span v-if="item.specAttribute3">-{{item.specAttribute3}}</span>:</span>
            <span
                style="padding:5px 10px;border: 1px solid rgb(81, 205, 203);border-radius:5px;margin-left:5px"
            >{{ item.specNum }} <i style="color:red">{{ (item.price/100).toFixed(2) }}元 </i>
              </span>
          </div>
        </template>
      </el-form-item>

      <el-form-item label="商品图片">
        <template v-for="(item, index) in detailsDate.goodPhoto">
          <el-image
            :key="index"
            style="width: 150px; height: 150px;border-radius:5px;margin-right:10px"
            :src="item"
          ></el-image>
        </template>
      </el-form-item>
      <hr
        style="
          background-color: #e4e4e4;
          border: none;
          height: 1px;
          margin: 30px 0;
        "
      />
      <el-form-item label="商品详情">
        <quill-editor v-model="detailsDate.goodsDetails"
      /></el-form-item>

      <el-form-item label-width="0px">
        <el-button size="medium" style="margin-left:80px" @click="isShow = true">预览</el-button>
      </el-form-item>
    </el-form>
    <prevGoods :isShow="isShow"
                :prod_imgs="detailsDate.goodPhoto"
                :prod-size="detailsDate.goodSpecModelList"
                :prod_price="String(detailsDate.price)"
                :edit_html="detailsDate.goodsDetails"
                @close="prodClose"
                ></prevGoods>
  </div>
</template>

<script>
// @ts-ignore
import prevGoods from './components/prevGoods'
export default {
  components:{
    prevGoods
  },
  data() {
    return {
      state:0,
      detailsDate: {},
      dialogImageUrl: "",
      dialogVisible: false,
      isShow:false,
    };
  },

  created() {
    console.log(this.$route.query.state)
    this.state = this.$route.query.state
    this.detailsDate = JSON.parse(this.$route.query.details);
    if (this.detailsDate.goodSpecModelList) {
      this.detailsDate.goodSpecModelList = this.detailsDate.goodSpecModelList
    }else {
      this.detailsDate.goodSpecModelList = [
        {end: 0},
        {goodsId: ''},
        {isDel: 0},
        {parentId: ''},
        {price: 1},
        {requiredSelect: false},
        {specAttribute: ""},
        {specId: ''},
        {specNum: 1},
        {start: 0},
        {state: 1},
      ]
    }
    this.detailsDate.goodPhoto = this.detailsDate.goodPhoto
      ? this.detailsDate.goodPhoto.split(",")
      : [];
  },

  methods: {
    prodClose(){
      this.isShow = false
    }
  },
};
</script>
